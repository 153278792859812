<template>
    <div id="margin-dialog-body">
        <v-container>
            <v-row>
                <v-col cols="12" sm="12" md="3" class="mx-auto text-center">
                    <v-card-text
                        class="
                            grey--text
                            font-weight-bold
                            text-center
                            display-1
                        "
                        sm="3"
                        md="12"
                    >
                        <v-icon color="grey" size="180" class="ma-1 pa-1 pt-8">
                            mdi-lightbulb
                        </v-icon>
                        Antes de começar
                    </v-card-text>
                    <v-card-text
                        class="
                            grey--text
                            font-weight-regular
                            text-center
                            subtitle
                        "
                        sm="3"
                        md="12"
                        >Baixe o modelo de planilha que disponibilizamos no
                        botão abaixo:
                    </v-card-text>
                    <v-btn
                        class="white--text"
                        color="grey"
                        rounded
                        x-large
                        depressed
                        @click="telaDicas"
                        >Dicas de Upload</v-btn
                    >
                </v-col>
                <v-col cols="12" sm="12" md="3" class="mx-auto my-auto">
                    <v-card-text
                        class="grey--text font-weight-black display-1 mt-4"
                        sm="3"
                        md="12"
                    >
                        1.Posso utilizar outro template para
                        upload?</v-card-text
                    >
                    <v-card-text
                        class="grey--text font-weight-regular"
                        sm="3"
                        md="12"
                        >Nosso sistema possui campos mapeados, por isso não é
                        possível utilizar outro arquivo ou um novo template para
                        upload. Caso utilizar outro arquivo, esse arquivo poderá
                        não ser compatível com o sistema.</v-card-text
                    >
                </v-col>
                <v-col cols="12" sm="12" md="3" class="mx-auto my-auto">
                    <v-card-text
                        class="
                            grey--text
                            font-weight-black
                            display-1
                            mt-4
                            justify-start
                        "
                        sm="3"
                        md="12"
                    >
                        2. Como devo preencher as colunas da
                        planilha?</v-card-text
                    >
                    <v-card-text
                        class="grey--text font-weight-regular justify-start"
                        justify-center
                        sm="3"
                        md="12"
                        >Após baixar o template modelo, siga as instruções que
                        existem em cada coluna. Fique atento a formatação de
                        algumas colunas como Datas e documentos, pois possuem
                        formatação correta.</v-card-text
                    >
                </v-col>
                <v-col cols="12" sm="12" md="3">
                    <v-card color="primary" height="500" shaped outlined>
                        <v-card-text
                            class="
                                white--text
                                font-weight-bold
                                text-center
                                display-1
                            "
                            sm="3"
                            md="12"
                        >
                            <v-icon
                                color="white"
                                size="400%"
                                class="ma-1 pa-1 pt-8"
                            >
                                download
                            </v-icon>
                            <v-btn
                                color="white"
                                rounded
                                x-large
                                depressed
                                :href="link_modelo"
                                >Baixar Template</v-btn
                            >
                        </v-card-text>
                        <v-card-text
                            class="
                                white--text
                                font-weight-regular
                                text-center
                                subtitle
                            "
                            sm="3"
                            md="12"
                            >Clique aqui para fazer o download do arquivo
                            template XLS
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>
        <v-dialog
            v-if="dialogManual"
            v-model="dialogManual"
            max-width="1200px"
            persistent
            color="background"
            transition="dialog-bottom-transition"
        >
            <!-- Innformações sobre o Upload -->
            <v-card class="">
                <v-toolbar flat dark color="primary">
                    <v-btn icon dark @click="closeDialog">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>

                    <v-col cols="12" sm="12" align="left">
                        <v-toolbar-title>Manual de Upload</v-toolbar-title>
                    </v-col>
                </v-toolbar>
                <v-card-text>
                    <ManualUpload
                        :dialogManual="dialogManual"
                        @fecharDialogManual="closeDialog"
                    />
                </v-card-text>
            </v-card>
            <!-- fim das informações sobre o Upload -->
        </v-dialog>
    </div>
</template>

<script>
import ManualUpload from '@/components/ManualUpload';

export default {
    name: 'DicasUpload',
    components: {
        ManualUpload
    },
    data: () => ({
        link_modelo: '/RECUPERI-Planilha_Protesto2024_v4.xlsx',
        dialogManual: false
    }),
    methods: {
        telaDicas() {
            this.dialogManual = true;
            document.removeEventListener('keyup', this.keyupListener);
        },
        closeDialog() {
            this.dialogManual = false;
            document.addEventListener('keyup', this.keyupListener);
        },
        keyupListener(e) {
            if (!e) e = window.event;
            const keyCode = e.code || e.key;

            if (keyCode === 'Escape') {
                this.$emit('fecharModal');
            }
        }
    },
    beforeDestroy() {
        document.removeEventListener('keyup', this.keyupListener);
    },
    mounted() {
        document.addEventListener('keyup', this.keyupListener);
    }
};
</script>

<style scoped>
#margin-dialog-body {
    margin-top: 15px;
    margin-right: 15px;
    margin-bottom: 15px;
    margin-left: 0px;
}
</style>
